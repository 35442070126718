<template>
	<div class="index">
		<swiper :options="swiperOption" ref="mySwiper" v-if="bannerList.length > 0" class="bannerSwiper">
			<swiper-slide class="swiper-slide" v-for="(item, index) in bannerList" :key="index">
				<img :src="item.image" mode="widthFit" class="banner" />
				<div class="textBox" data-swiper-parallax="-300" data-swiper-parallax-opacity="0">
					<div class="big">{{ item.title }}</div>
					<div class="samll">{{ item.subtitle }}</div>
					<div class="bannerBtn" @click="toLink(item.dump_link)" v-if="item.button_status">
						{{ lang == 6 ? '了解更多' : 'read more' }}
					</div>
				</div>
			</swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div>
		</swiper>
		<div class="mode1">
			<div class="mode1-item">
				<div class="mode1-item-text">
					<p class="big">{{ lang == 6 ? '集团故事' : 'Group Story' }}</p>
				</div>
				<!-- 蒙版 -->
				<div class="mode1-item-mask">
					<div class="mode1-item-mask-btnBox">
						<div class="btn" @click="$router.push('/brandStory/introduce')">
							{{ lang == 6 ? '集团介绍' : 'Profile' }}
						</div>
						<div class="btn" @click="$router.push('/news')">{{ lang == 6 ? '集团新闻' : 'News' }}</div>
						<div class="btn" @click="$router.push('/history')">{{ lang == 6 ? '发展历程' : 'Milestones' }}</div>
						<!-- 
						<div class="btn" @click="$router.push('/socialResponsibility')">{{lang==6?'企业社会责任':'Social Responsibility'}}
						</div> -->
					</div>
				</div>
			</div>
			<div class="mode1-item">
				<div class="mode1-item-text">
					<p class="big">{{ lang == 6 ? '客户活动' : 'Customer Activities' }}</p>
				</div>
				<!-- 蒙版 -->
				<div class="mode1-item-mask">
					<div class="mode1-item-mask-btnBox">
						<!-- @click="$router.push('/activityGroup')" -->
						<div class="btn">{{ lang == 6 ? '敬请期待' : 'Coming Soon' }}
						</div>
					</div>
				</div>
			</div>
			<div class="mode1-item">
				<div class="mode1-item-text">
					<p class="big">{{ lang == 6 ? '企业社会责任' : 'CSR' }}</p>
				</div>
				<!-- 蒙版 -->
				<div class="mode1-item-mask">
					<div class="mode1-item-mask-btnBox">
						<div class="btn" @click="$router.push('/sustainability')">
							{{ lang == 6 ? '可持续发展' : 'Sustainability' }}
						</div>
						<div class="btn" @click="$router.push('/paigWomen')">
							{{ lang == 6 ? '多元化与包容性' : 'Diversity & Inclusion' }}
						</div>
						<!-- <div class="btn" @click="$router.push('/Environment')">{{lang == 6 ? '环境保护':'Environment'}}
						</div> -->
					</div>
				</div>
			</div>
			<div class="mode1-item">
				<div class="mode1-item-text">
					<p class="big">{{ lang == 6 ? '关于我们' : 'About Us' }}</p>
				</div>
				<div class="mode1-item-mask">
					<div class="mode1-item-mask-btnBox">
						<div class="btn" @click="$router.push('/aboutUs/brandValue')">
							{{ lang == 6 ? '核心价值' : 'Core Values' }}
						</div>
						<div class="btn" @click="$router.push('/compliance')">
							{{ lang == 6 ? '合规与廉正' : 'Compliance & Integrity' }}
						</div>
						<div class="btn" @click="$router.push('/aboutUs/AFCC')">
							{{ lang == 6 ? '人才发展与学习中心' : 'TLC' }}
						</div>
						<div class="btn" @click="$router.push('/aboutUs/cic')">{{ lang == 6 ? '客户互动服务中心' : 'CIC' }}
						</div>

						<div class="btn" @click="$router.push('/aboutUs/client')">
							{{ lang == 6 ? '集团大客户' : 'Group Fleet' }}
						</div>

						<!-- <div class="btn" @click="toLink('https://porsche-holding.51job.com/index.html')">
							{{lang==6?'招贤纳才':'Jobs at PAIG'}}</div> -->


						<!-- <div class="btn" @click="$router.push('/aboutUs/cooperation')">cooperation</div> -->
					</div>
				</div>
			</div>
		</div>
		<!-- 品牌列表 -->
		<!--  -->
		<div class="mode3">
			<div class="mode3-content">
				<div class="mode3-item" @click="$router.push(`/brand/${item.id}`)"
					:style="`background-image:url(${item.image})`" v-for="(item, index) in brandList" :key="index">
					<!-- <div class="mode3-item-text">
						<p class="mode3-item-text-tit">
							<span>{{item.title}}</span>
							<img @click="changemode3(item.id)" src="../assets/top/open.png" class="open" />
							<img @click="changemode3(item.id)" src="../assets/top/close.png" class="close" />
						</p>
						<div class="mode3-item-text-con">
							<p @click="$router.push(`/brand/${item.id}?id=activity`)">
								{{lang==6?'近期活动':'Recent Activities'}}
							</p>
							<p @click="$router.push(`/brand/${item.id}?id=Gallery`)">{{lang==6?'陈列':'Gallery'}}</p>
							<p @click="$router.push(`/brand/${item.id}?id=dealer`)">{{lang==6?'寻找经销商':'Find a Dealer'}}
							</p>
							<p @click="$router.push(`/brand/${item.id}?id=summarized`)">
								{{lang==6?'品牌概括':'Brand Summary'}}
							</p>
						</div>
					</div> -->
				</div>
				<!-- 	<div class="mode3-item" :style="`background-image:url(${require('../assets/index/mode3-img6'+(lang==6?'1':'EN')+'.png')})`">
					<div class="mode3-item-text">
						<p class="mode3-item-text-tit" style="display: flex;">{{ lang == 6 ? '趣选二手车' : 'Q-Car' }}</p>
					</div>
				</div> -->

			</div>
		</div>
		<!-- 宝爱捷集团 -->
		<div class="mode4">
			<div class="mode4-img wow fadeInLeftBig">
				<img src="../assets/index/icon.gif" class="icon" />
				<img src="../assets/index/icon.gif" class="icon" />
				<img src="../assets/index/icon.gif" class="icon" />
				<img src="../assets/index/icon.gif" class="icon" />
				<img src="../assets/index/icon.gif" class="icon" />
				<img src="../assets/index/icon.gif" class="icon" />
				<img class="mode4-img-bg"
					:src="require('../assets/index/mode2-img-test' + (lang == 6 ? '' : 'EN') + '.png')" />
			</div>
			<div class="mode2-text mode4-text wow fadeInRightBig">
				<div class="top">
					<div class="titTop" :class="lang == 6 ? '' : 'titBottomEn'">
						{{ lang == 6 ? '宝爱捷（中国）汽车投资有限公司' : 'PAIG (China) Automobile Investment' }}
					</div>
					<div :class="lang == 6 ? '' : 'titBottomEn'" class="titBottom">
						{{ lang == 6 ? '共创移动出行的美好未来！' : 'SHAPING MOBILITY FOR A BETTER FUTURE!' }}
					</div>
					<!-- 	<p class="titText">
						{{lang==6?'宝爱捷（中国）汽车投资有限公司，也被称为“保时捷汽车投资中国分公司”，是萨尔茨堡保时捷控股有限公司的全资子公司，全面负责中国区业务的运营管理和经销商网络部署。':'PAIG (China) Automobile Investment Co., Ltd., also known as "Porsche Automotive Investment, China Branches" is a 100% daughter company of Porsche Holding Salzburg. It is fully responsible for all operational business activities and the dealer network development in China.'}}
					</p> -->
					<div class="titbtn" @click="$router.push('/brandStory/introduce')">
						{{ lang == 6 ? '了解更多' : 'EXPLORE MORE' }}
					</div>

				</div>
			</div>
		</div>
		<div class="mode5 wow fadeInRight" style="`background-image:url(../assets/index/mode5-bg.png)`">
			<div class="mode2-text mode5-text">
				<div class="titTop">JOIN US</div>
				<div class="titBottom">{{ lang == 6 ? '开启您的职业之旅' : 'Start your career' }}</div>
				<p class="titText" v-if="lang == 6">
					作为来自欧洲的汽车经销商集团，我们不仅能为您提供卓越的产品和服务，<br>还能为您提供广阔的职业前景。<br>查看我们的热招岗位，开启您的职业发展。</p>
				<p class="titText" v-else>As a successful automotive dealer group from Europe, we can offer you not only
					fascinating products and excellent service, but also interesting career prospects.<br /> Discover
					your
					career opportunities by viewing our current job vacancies.</p>
				<div class="titbtn" @click="toLink('https://porsche-holding.51job.com/index.html')">
					{{ lang == 6 ? '了解更多' : 'read more' }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import mixinLang from '../components/mixin.js'
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper/src/index.js";
	import 'swiper/dist/css/swiper.css';
	export default {
		metaInfo: {
			title: '宝爱捷(中国)汽车投资有限公司', // set a title
			meta: [{ // set meta
					name: 'keyWords',
					content: '宝爱捷，保时捷控股, 保时捷车队，宝马经销商，奥迪经销商，宾利经销商，兰博基尼经销商，保时捷经销商，MINI经销商'
				},
				{
					name: 'description',
					content: ''
				}
			]
		},
		name: 'index',
		mixins: [mixinLang],
		components: {
			swiper,
			swiperSlide
		},
		data() {
			return {
				mode3Cur: '',
				// 品牌列表
				brandList: [],
				bannerList: [],
				// 轮播配置
				brandswiperOption: {
					slidesPerView: "auto",
					loop: false,
				},
				// 轮播配置
				swiperOption: {
					// parallax: true,
					lazyLoading: true,
					watchSlidesVisibility: true,
					slidesPerView: 1,
					loop: true,
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false
					},
					// 显示分页
					pagination: {
						el: ".swiper-pagination",
						clickable: true //允许分页点击跳转
					}
				}
			}
		},
		methods: {
			toLink(url) {
				window.open(url)
			},
			getInfo() {
				var that = this
				// 品牌列表
				this.$api.getBrandList({
					cate_no: 'b4766587',
				}, {
					page: 1,
					limit: 100
				}).then(res => {
					this.brandList = res.list
				})
				// 获取banner
				this.bannerList = []
				this.$api.getBannerList({
					cate_no: 'wa54f4xc'
				}).then(res => {
					this.$nextTick(function() {
						that.bannerList = res.banner_list
						this.$wow.init()
					})
				})
			},
			toPage(url) {
				window.location = url
			},
			changemode3(id) {
				var that = this
				that.$nextTick(() => {
					// that.mode3Cur ? that.mode3Cur = '' : that.mode3Cur = id
					if (that.mode3Cur == id) {
						that.mode3Cur = 2
					} else {
						that.mode3Cur = id
						console.log(that.mode3Cur)
					}
				})

			}
		}
	}
</script>

<style lang="scss" scoped>
	/deep/ .swiper-container {
		transform: translate3d(0, 0, 0);
	}

	/deep/.swiper-slide {
		transform: translate3d(0, 0, 0);
	}

	.bannerSwiper {
		.banner {
			width: 100%;
			height: 100vh;
			vertical-align: middle;
		}

		.swiper-slide {
			width: 100vw;
			position: relative;
		}

		.textBox {
			position: absolute;
			left: 138px;
			top: 180px;
			color: #FFF;
			font-size: 32px;
			font-weight: normal;

			.big {
				font-weight: bold;
				font-size: 64px;
			}

			.bannerBtn {
				width: 180px;
				height: 60px;
				border-radius: 6px;
				background: #97233F;
				color: #FFF;
				text-align: center;
				line-height: 60px;
				font-size: 24px;
				/*px*/
				font-weight: bold;
				margin-top: 20px;
				cursor: pointer;
			}
		}

		/deep/.swiper-pagination-bullet {
			width: 16px;
			height: 16px;
			background-color: #D8D8D8;
			opacity: 1;
		}

		/deep/ .swiper-pagination-bullet-active {
			background-color: #97233F;
		}
	}

	.mode1 {
		width: 100%;
		display: flex;
		color: #FFF;
		text-align: center;

		.mode1-item {
			flex: 1;
			height: 540px;
			background-size: 100% 100%;
			font-size: 20px;
			padding: 94px 0;
			position: relative;
			overflow: hidden;

			.mode1-item-text {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
				text-transform: uppercase;
				font-weight: bold;
				width: 100%;

				// margin-bottom: 90px;

				&::after {
					content: '';
					display: block;
					position: absolute;
					bottom: -44px;
					width: 73.33px;
					height: 4px;
					background: #97233F;
					left: calc(50% - (73.33px / 2));
				}

				.big {
					font-size: 27px;
					font-family: SourceHanSansCN-Bold, sans-serif;
				}
			}

			.btn {
				min-width: 160px;
				height: 36px;
				border: 1px solid #FFFFFF;
				border-radius: 3px;
				line-height: 36px;
				margin: 0 auto;
				cursor: pointer;
				padding: 0 20px;
			}

			.mode1-item-mask {
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: #000;
				opacity: 0.85;
				top: 540px;

				.mode1-item-mask-btnBox {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);

					.btn {
						font-size: 20px;
						margin-bottom: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 25px 10px;
						white-space: nowrap;
						cursor: pointer;
					}

					.btn:last-child {
						margin-bottom: 0 !important;
					}
				}
			}

			&:hover {
				.mode1-item-mask {
					animation: slideTop .2s linear 1 forwards;
				}
			}

			@keyframes slideTop {
				0% {
					top: 350px;
				}

				100% {
					top: 0px
				}
			}
		}

		.mode1-item:nth-child(1) {
			background-image: url(../assets/index/mode1-img1.png)
		}

		.mode1-item:nth-child(2) {
			background-image: url(../assets/index/mode1-img2.png)
		}

		.mode1-item:nth-child(3) {
			background-image: url(../assets/index/mode1-img3.png)
		}

		.mode1-item:nth-child(4) {
			background-image: url(../assets/index/mode1-img4.png);

			// .mode1-item-mask-btnBox {
			// 	width: 100%;
			// 	display: flex !important;
			// 	flex-wrap: wrap;
			// 	padding: 20px 40px 0;
			// 	justify-content: space-between;

			// 	.btn {
			// 		width: 190px;
			// 		line-height: 35px;
			// 		cursor: pointer;
			// 	}
			// }
		}
	}

	.mode2 {
		display: flex;
		height: 730px;
		padding: 75px 113px;
		justify-content: space-between;
		position: relative;
		background-color: #EEEEEE;

		.mode2-text {
			width: 619px;
			height: 593px;
			overflow: hidden;
			font-family: SourceHanSansCN-Regular, sans-serif;
			font-size: 18px;
			font-weight: normal;
			line-height: 40px;
			color: #787878;
		}

		img {
			width: 941px;
			height: 593px;
		}
	}

	.titTop {
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 35px;
		font-weight: bold;
		color: #B5A191;
		// margin-bottom: 15px;
		position: relative;

		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 129.59px;
			height: 4px;
			background: #97233F;
			top: -10px;
			left: 0;
		}
	}

	.titBottom {
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 40px;
		font-weight: bold;
		color: #FFF;
	}

	.titText {
		line-height: 40px !important;
		// display: -webkit-box;
		// -webkit-box-orient: vertical;
		// -webkit-line-clamp: 4;
		// overflow: hidden;
	}

	.titbtn {
		padding: 0 20px;
		display: inline-block;
		min-width: 220px;
		// width: 200px;
		height: 56px;
		border-radius: 6px;
		background: #97233F;
		line-height: 56px;
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 22px;
		color: #FFFFFF;
		text-align: center;
		margin-top: 50px;
		cursor: pointer;
		text-transform: uppercase;
	}


	.mode3 {
		color: #FFF;
		position: relative;
		padding: 130px 30px;
		background-color: #EEEEEE;

		.mode3-content {
			display: flex;
			justify-content: space-between;
		}

		.mode3-arrows-icon {
			width: 72px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 99;

		}

		.mode3-arrows-ricon {
			left: 30px;
		}

		.mode3-arrows-licon {
			right: 30px;
			transform: rotate(-180deg);
		}

		.mode3-item {
			width: 240px;
			height: 280px;
			display: inline-block;
			background-size: 100% 100%;
			background-position: center;
			background-repeat: no-repeat;
			position: relative;
			border-radius: 8px;
			box-shadow: 0px 62.82px 67.31px 0px rgba(0, 0, 0, 0.16), 0px 14.58px 23.56px 0px rgba(0, 0, 0, 0.24);
			// box-shadow: 0px 15px 20px -15px #000;
			vertical-align: middle;
			padding: 29px 23px;

			&:last-child {
				.mode3-item-text-tit {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				.mode3-item-text {
					position: relative;
				}

				.mode3-item-text-con {
					width: 100%;
					text-align: center;
					font-weight: bold;
					// font-size: 28px;
					// height: 30px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;

					img {
						// height: 30px;
						object-fit: cover;
					}
				}
			}

			&:hover {
				animation: largen .5s linear forwards;
			}

			@keyframes largen {
				0% {
					transform: scale(1);
				}

				100% {
					transform: scale(1.1);
				}
			}

			.mode3-item-text {
				height: 100%;
				font-family: SourceHanSansCN-Bold, sans-serif;
				font-size: 24px;
				font-weight: bold;
				position: relative;

				.mode3-item-text-tit {
					position: absolute;
					top: -10px;
					right: 0px;
				}

				.close {
					display: none;
				}

				&:hover {
					position: relative;

					p {
						z-index: 9;
						position: relative;
					}

					.mode3-item-text-con {
						display: block;
						z-index: 9;
						position: relative;
					}

					// &::after {
					// 	width: 240px;
					// 	height: 280px;
					// 	top: -29px;
					// 	left: -23px;
					// 	content: '';
					// 	position: absolute;
					// 	background: rgba(54, 59, 73, 0.9);
					// 	z-index: 1;
					// 	border-radius: 8px;
					// }
				}

				&:hover .close {
					display: inline-block;
				}

				&:hover .open {
					display: none;
				}

				img {
					width: 18px;
					height: 10px;
					// margin-left: 20px;
				}

				.mode3-item-text-con {
					margin-top: 10px;
					font-family: SourceHanSansCN-Normal, sans-serif;
					font-size: 14px;
					font-weight: 350;
					line-height: 40px;
					display: none;

					>p {
						cursor: pointer;
					}
				}
			}

			@media (max-width:950px) {
				.mode3-item-text-con p {
					font-size: 14px;
					line-height: 30px;
				}
			}

			.btn {
				width: 140px;
				height: 40px;
				line-height: 38px;
				border-radius: 3px;
				border: 1px solid #FFFFFF;
				font-family: SourceHanSansCN-Bold, sans-serif;
				font-size: 18px;
				font-weight: bold;
				text-align: center;
				position: absolute;
				bottom: 20px;
				right: 20px;
				cursor: pointer;
			}
		}
	}

	.mode4 {
		display: flex;
		height: 540px;
		padding: 55px 120px 55px 54px;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		position: relative;
		background: linear-gradient(90deg, #1F2634 0%, #202938 100%);

		.titTop {
			margin-bottom: 0;
		}

		.mode4-img {
			position: relative;

			.icon {
				position: absolute;
				width: 50px;

				&:nth-child(1) {
					right: 215px;
					top: 140px;
				}

				&:nth-child(2) {
					right: 190px;
					top: 190px;
				}

				&:nth-child(3) {
					right: 160px;
					top: 240px;
				}

				&:nth-child(4) {
					right: 250px;
					top: 295px;
				}

				&:nth-child(5) {
					right: 320px;
					top: 225px;
				}

				&:nth-child(6) {
					right: 160px;
					top: 215px;
				}
			}

			.mode4-img-bg {
				width: 1000px;
			}
		}

		.mode4-text {
			width: 683px;
			height: 300px;

			.titbtn {
				margin-top: 90px;
			}

			.titTop {
				font-size: 35px;
				margin-bottom: 20px;
			}

			.titBottom {
				font-size: 40px;
			}

			.titBottomEn {
				font-size: 32px;
				white-space: nowrap;
			}

			.top {
				height: 427px;
				position: relative;

				.titText {
					color: #FFF;
				}
			}
		}

		.swiperBox {
			position: relative;

			&::after {
				content: '';
				display: block;
				position: absolute;
				left: -15px;
				top: -15px;
				width: 204.02px;
				height: 320px;
				background: #97233F;
			}
		}

		/deep/.swiper-wrapper {
			width: 1200px;
			background-color: #FFF;
		}

		/deep/ .swiper-slide {
			width: 520px !important;

			img {
				width: 500px;
				height: 320px;
			}
		}

		.mode4-btnBox {
			// margin-top: 130px;
			position: absolute;
			bottom: 0;

			img {
				width: 64px;
				cursor: pointer;
			}

			.left {
				transform: rotate(-180deg);
				margin-right: 10px;
			}
		}
	}

	.mode5 {
		width: 100%;
		position: relative;
		height: 540px;
		background: url(../assets/index/mode5-bg.png) no-repeat top/100% 100%;

		.titBottom {
			color: #FFF;
		}

		.mode5-text {
			position: absolute;
			top: 74px;
			left: 200px;
			width: 950px;
			color: #FFF;

			.titText {
				font-size: 20px;
				// display: -webkit-box;
				// -webkit-box-orient: vertical;
				// -webkit-line-clamp: 3;
				// overflow: hidden;
			}
		}
	}

	.mode7 {
		background: url(../assets/index/mode7-bg.png) no-repeat top/100% 100%;
		background-size: 100% 100% !important;

		.mode5-text {
			left: auto;
			right: 135px !important;
		}
	}

	.titBottom {
		text-transform: none;
	}
</style>